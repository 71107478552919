import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import Stepper from "./Stepper";
import { findAllByKey } from "../utils";
import JSZip from "jszip";
import X2JS from "x2js";
import { saveAs } from "file-saver";

function FormAuthor() {
  const [file, setFile] = useState("");
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);
  const [author, setAuthor] = useState({ name: "", initials: "" });
  const zip = new JSZip();

  const handleClick = () => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      zip.loadAsync(reader.result).then((data) => {
        const comms = data.files["word/comments.xml"];
        const Document = data.files["word/document.xml"];
        const coreXml = data.files["docProps/core.xml"];
        const x2js = new X2JS({ stripWhitespaces: false });
        const filename = file.name.split(".")[0];
        let promises = [];  // Array to hold promises
  
        if (Document) {
          promises.push(
            Document.async("string").then((content) => {
              const jsXml = x2js.xml2dom(content);
              const traverseAndReplaceAuthors = (node) => {
                if (node.nodeType === 1) {
                  const authorAttribute = node.attributes["w:author"];
                  if (authorAttribute) {
                    const authorValue = authorAttribute.nodeValue;
                    if (selected.includes(authorValue)) {
                      authorAttribute.nodeValue = author.name;
                    }
                  }
                }
    
                let child = node.firstChild;
                while (child) {
                  traverseAndReplaceAuthors(child);
                  child = child.nextSibling;
                }
              };
    
              traverseAndReplaceAuthors(jsXml);
    
              const sereial = new XMLSerializer();
              const xm = sereial.serializeToString(jsXml);
              data.file("word/document.xml", xm);
            })
          );
        }
  
        if (comms) {
          promises.push(
            comms
              .async("string")
              .then((content) => {
                const jsXml = x2js.xml2dom(content);
                const comments = jsXml.querySelectorAll("comment");
                comments.forEach((comment) => {
                  if (selected.includes(comment.attributes["w:author"].nodeValue)) {
                    comment.attributes["w:author"].nodeValue = author.name;
                    comment.attributes["w:initials"].nodeValue = author.initials;
                  }
                });
                const sereial = new XMLSerializer();
                const xm = sereial.serializeToString(jsXml);
                data.file("word/comments.xml", xm);
              })
          );
        }
  
        if (coreXml) {
          promises.push(
            coreXml.async("string").then((content) => {
              const jsXml = x2js.xml2dom(content);
              const lastModifiedByElements = jsXml.getElementsByTagNameNS(
                "http://schemas.openxmlformats.org/package/2006/metadata/core-properties",
                "lastModifiedBy"
              );
              if (lastModifiedByElements.length > 0) {
                const lastModifiedBy = lastModifiedByElements[0];
                if (selected.includes(lastModifiedBy.textContent)) {
                  lastModifiedBy.textContent = author.name;  // Replace with new name
                }
              }
        
              // Adding code to replace document author
              const authorElements = jsXml.getElementsByTagNameNS(
                "http://purl.org/dc/elements/1.1/",
                "creator"
              );
              if (authorElements.length > 0) {
                const docAuthor = authorElements[0];
                if (selected.includes(docAuthor.textContent)) {
                  docAuthor.textContent = author.name;  // Replace with new name
                }
              }
        
              const sereial = new XMLSerializer();
              const xm = sereial.serializeToString(jsXml);
              data.file("docProps/core.xml", xm);
            })
          );
        }
        
        
  
        Promise.all(promises).then(() =>
          data
            .generateAsync({ type: "blob" })
            .then((content) => saveAs(content, `${filename}_cleaned.docx`))
        );
      });
    };
  };
  
  
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        zip.loadAsync(reader.result).then((data) => {
          const x2js = new X2JS();
          const comments = data.files["word/comments.xml"];
          const Document = data.files["word/document.xml"];
          const coreXml = data.files["docProps/core.xml"];
          let authorList = [];
          const promises = [];
  
          const traverseAndExtractAuthors = (node) => {
            if (node.nodeType === 1) {
              const authorAttribute = node.attributes["w:author"];
              const initialsAttribute = node.attributes["w:initials"];
              if (authorAttribute) {
                const authorValue = authorAttribute.nodeValue;
                const initialsValue = initialsAttribute ? initialsAttribute.nodeValue : "";
                authorList.push({ name: authorValue, initials: initialsValue });
              }
            }
  
            let child = node.firstChild;
            while (child) {
              traverseAndExtractAuthors(child);
              child = child.nextSibling;
            }
          };
  
          if (Document) {
            promises.push(
              Document.async("string").then((content) => {
                const jsXml = x2js.xml2dom(content);
                traverseAndExtractAuthors(jsXml);
              })
            );
          }
  
          if (comments) {
            promises.push(
              comments.async("string").then((content) => {
                const jsXml = x2js.xml2dom(content);
                traverseAndExtractAuthors(jsXml);
              })
            );
          }
  
          if (coreXml) {
            promises.push(
              coreXml.async("string").then((content) => {
                const jsXml = x2js.xml2dom(content);
                const lastModifiedByElements = jsXml.getElementsByTagNameNS(
                  "http://schemas.openxmlformats.org/package/2006/metadata/core-properties",
                  "lastModifiedBy"
                );
                if (lastModifiedByElements.length > 0) {
                  const lastModifiedBy = lastModifiedByElements[0];
                  authorList.push({ name: lastModifiedBy.textContent, initials: "" });
                }
          
                // Adding code to extract document author
                const authorElements = jsXml.getElementsByTagNameNS(
                  "http://purl.org/dc/elements/1.1/",
                  "creator"
                );
                if (authorElements.length > 0) {
                  const docAuthor = authorElements[0];
                  authorList.push({ name: docAuthor.textContent, initials: "" });
                }
              })
            );
          }
          
  
          Promise.all(promises).then(() => {
            let uniqueAuthorList = [];
            let addedNames = new Set();
  
            for (let author of authorList) {
              if (!addedNames.has(author.name)) {
                uniqueAuthorList.push(author);
                addedNames.add(author.name);
              } else {
                let existingAuthor = uniqueAuthorList.find((a) => a.name === author.name);
                if (existingAuthor && author.initials !== "") {
                  existingAuthor.initials = author.initials;
                }
              }
            }
  
            setList(uniqueAuthorList);
          });
        });
      };
    }
  }, [file]);
  
  

  return (
    <Container>
      <Form>
        <Stepper
          file={file}
          setFile={setFile}
          setSelected={setSelected}
          selected={selected}
          author={author}
          setAuthor={setAuthor}
          list={list}
          handleClick={handleClick}
        />
      </Form>
    </Container>
  );
}

export default FormAuthor;
