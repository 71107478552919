import React from "react";
import FormAuthor from "./components/FormAuthor";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="app container col-lg-12 mx-auto">
      <FormAuthor />
    </div>
  );
}

export default App;
