import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
function AuthorChange({ data, setSelected, selected }) {
  console.log(selected);
  const columns = [
    {
      dataField: "initials",
      text: "Initials",
    },
    {
      dataField: "name",
      text: "Name",
    },
  ];
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prev) => [...prev, row.name]);
    } else {
      setSelected(selected.filter((item) => item !== row.name));
    }
  };
  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelected(rows.map((row) => row.name));
    } else {
      setSelected([]);
    }
  };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected,
  };
  return (
    <BootstrapTable
      keyField="name"
      data={data}
      columns={columns}
      selectRow={selectRow}
      noDataIndication={"No authors found"}
    />
  );
}

export default AuthorChange;
