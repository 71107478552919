import React, { useEffect } from "react";

import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

import useQueryParams from "../hooks/useQueryParams";

function ReplaceAuthor({ author, setAuthor, file, selected, handleClick }) {
  const token = useQueryParams(window.location.search, "token");

  const defaultName = "Author anonymized by authorcleaner.com";
  const defaultInitials = "Demo";

  useEffect(() => {
    if (!token) {
      setAuthor(prev => ({
        ...prev,
        name: defaultName,
        initials: defaultInitials,
      }));
    }
  }, [token]);

  return (
    <Card>
      <Card.Header className="">Please enter the author name that should be displayed instead</Card.Header>
      <Card.Body>
        <Form.Label>New Author's Name</Form.Label>
        <Form.Control
          type="text"
          value={author.name}
          disabled={!token}
          onChange={(e) =>
            setAuthor((prev) => ({ ...prev, name: e.target.value }))
          }
        />
        <Form.Label>New Author's Initials</Form.Label>
        <Form.Control
          type="text"
          value={author.initials}
          disabled={!token}
          onChange={(e) =>
            setAuthor((prev) => ({ ...prev, initials: e.target.value }))
          }
        />
      </Card.Body>
    </Card>
  );
}

export default ReplaceAuthor;
