import React from "react";
import Card from "react-bootstrap/Card";

function Completed({ jumpToStep }) {
  return (
    <Card>
      <Card.Header className="">Save your new document</Card.Header>
      <Card.Body>
        The document has been cleaned successfully. You can now save your new document.{" "}
        <span
          className="link-primary"
          role="button"
          onClick={() => jumpToStep(0)}
        >
          Clean another document.
        </span>
      </Card.Body>
    </Card>
  );
}

export default Completed;
